import store from '@/store'
const auth = store.state.auth
export default {
  mustBeBuyer:  function (to, from, next) {
    return (auth.authenticated
      && auth.token != null
      && auth.user.crole == 'buyer') ? next() : next({ name: "home" });
  },
  mustBeSeller: function (to, from, next){
    return (auth.authenticated
            && auth.token != null
            && auth.user.crole == 'seller') ? next() : next({ name: "home"});
  },
  UserFullAccess: function (to, from, next){
    return (auth.user.access != false) ? next() : next({ name: "home"});
  },

  InstitutionalUser : function (to, from, next){
    console.log(auth.user.company_name);
    return auth.user.company_name ? next() : next({ name: "home"});
  }
};
