import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import multiguard from 'vue-router-multiguard';
import Buyer from './buyer.js'
import Seller from './seller.js'
//middleware
import middleware from './middlewares/index'
Vue.use(VueRouter);
const auth = store.state.auth
//another option
// const mustBeBuyer = function (to, from, next) {
//   return (auth.authenticated
//     && auth.token != null
//     && auth.user.crole == 'buyer') ? next() : next({ name: "home" });
// }

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...Buyer,
    ...Seller,
    {
      path: "/privacy",
      component: () => import("@/views/files/Privacy.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },

    },
    {
      path: "/team",
      name: "team",
      component: () => import("@/views/team/Team.vue"),
      beforeEnter: multiguard([
        middleware.UserFullAccess,
        middleware.InstitutionalUser,
      ]),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/privacy",
      component: () => import("@/views/files/Privacy.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },
    },
    {
      path: "/terms",
      component: () => import("@/views/files/Terms.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },

    },
    {
      path: "/confirm/invoice/:hash",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/dashboard/Home.vue"),
      meta: {
        middleware: "auth",
        redirect: 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website'
      },
    },

    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/Settings.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/settings/terms",
      component: () => import("@/views/files/Terms.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
      },

    },
    {
      path: "/settings/privacy",
      component: () => import("@/views/files/Privacy.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
      },

    },
    {
      path: "/balance",
      name: "balance",
      component: () => import("@/views/balance/Balance.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/billing",
      name: "billing",
      component: () => import("../components/billing/BillingInformation.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/identification",
      name: "identification",
      component: () => import("@/views/Identification/Identification.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/invoices-table",
      name: "settings",
      component: () => import("../components/invoices/Invoices.vue"),
      meta: {
        middleware: "auth",
      },
    },

    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/approve_ownership/:balanceCode",
      name: "Request",
      component: () => import("../views/RequestProof.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/transactions",
      name: "",
      component: () => import("../components/balance/Deposit.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/deposit",
      name: "balance",
      component: () => import("../components/balance/Deposit.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/files/:file",
      name: "Files",
      component: () => import("@/views/files/Files.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/read-files",
      name: "Documents",
      component: () => import("@/views/PdfViewer.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/download-document",
      name: "Download Document",
      component: () => import("@/views/PdfViewer.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/cookpit/task/:task",
      name: "add-company",
      component: () => import("@/views/dashboard/Cookpit.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/buyer/upload-bod",
      name: "upload-docs",
      component: () => import("../components/buyer-cookpit/UploadDocs.vue"),
      meta: {
        middleware: "auth",
      },
    },
    {
      path: "/verify",
      name: "verify",
      component: () => import("@/views/auth/Verify.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
        redirectIfLoggedIn: true,
      },
    },

    {
      path: "/two-factor-verify",
      name: "two_factor_verify",
      component: () => import("@/views/auth/TwoFactorVerify.vue"),
      meta: {
        layout: "full",
        middleware: "auth",
        redirectIfLoggedIn: true,
      },
    },

    {
      path: "/loginFromAdmin/:token",
      name: "loginFromAdmin",
      component: () => import("@/views/auth/LoginFromAdmin.vue"),
      meta: {
        layout: "full",
        middleware: "loginFromAdmin",
      },
    },
    {
      path: "/login",
      name: "login",

      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        redirect: 'https://marketing.aequitex.com/CH-DE/aequitex-pre-register-campaign-website'
      },
    },
    {
      path: "/login/en",
      name: "login",

      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        redirect: 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website'
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/auth/Register.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        role:'seller',
        redirect: 'http://marketing.aequitex.com/CH-DE/aequitex-pre-register-campaign-website'
      },
    },
    {
      path: "/register/en",
      name: "register",
      component: () => import("@/views/auth/Register.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        role:'seller',
        redirect: 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website'
      },
    },
    {
      path: "/register/buyer/:lang?",
      name: "register-buyer",
      component: () => import("@/views/auth/Register.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        role:'buyer',
        redirect: 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website'
      },
    },

    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/auth/ForgotPassword.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
        redirect: 'http://marketing.aequitex.com/CH-EN/aequitex-pre-register-campaign-website'
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/auth/ResetPassword.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },
    },
    {
      path: "/verify/:token/:pin",
      name: "verify-from-email",
      component: () => import("@/views/auth/VerifyFromEmail.vue"),
      meta: {
        layout: "full",
        middleware: "guest",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },

  ],
});

router.beforeEach((to, from, next) => {
  // if(to.meta.redirect != 'undefined'){
  //     window.location = to.meta.redirect;
  // }



  if (to.meta.middleware === "guest") {

    // console.log('/files/terms_de.pdf')
    // eslint-disable-next-line no-undef
    if (store.state.auth.authenticated && store.state.auth.token != null) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('source')) {
        if(params.has('id')) {
          window.location.href = "/" +  params.get('source') + '/' + params.get('id') ;
        }else {
          window.location.href = "/" +  params.get('source');
        }
      }else if(params.has('form')) {
        window.location.href = "/cookpit/task/fill-online-form"
      }
      next({ name: "dashboard" });
    }
    next();
    // eslint-disable-next-line no-undef
  }else if (to.meta.middleware === "loginFromAdmin") {

    localStorage.clear();
    next();
    // eslint-disable-next-line no-undef
  } else if (store.state.auth.authenticated) {
    next();
  } else {
    next({ name: "login" });
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
