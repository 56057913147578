export default {
  namespaced: true,
  state: {
    user: 0,
    token: null,
    status: 0,
    authenticated: false,
    resetPasswordEmail: null,
    alert:false,
    // themeColor: 'light', // Новое свойство
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    // themeColor(state) { // Новый геттер
    //   return state.themeColor;
    // },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SHOW_ALERT(state, alert){
      state.alert  = alert

    },
    SET_USER(state, value) {
      state.user = value
    },
    UPDATE_TOKEN(state, val) {
      state.token = val
    },
    UPDATE_RESET_EMAIL(state, val) {
      state.resetPasswordEmail = val
    },

    // SET_THEME_COLOR(state, color) { // Новая мутация
    //   state.themeColor = color;
    // },

  },
  actions: {},
}
