import multiguard from 'vue-router-multiguard';
import middleware from './middlewares/index'

export default [
  {
    path: "/check/hash",
    name: "check_hash",
    component: () => import("@/views/blockchain/CheckHash.vue"),
    beforeEnter: multiguard([
      middleware.mustBeBuyer,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
      role:'seller',
    },
  },
  {
    path: "/market",
    name: "market",
    component: () => import("@/views/market/Market.vue"),
    beforeEnter: multiguard([
      middleware.mustBeBuyer,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
    },
  },
  {
    path: "/market/:id",
    name: "market",
    component: () => import("@/components/market/MarketDetails.vue"),
    beforeEnter: multiguard([
      middleware.mustBeBuyer,
      middleware.UserFullAccess,
    ]),
    meta: {
      middleware: "auth",
    },
  },
];
