import Vue from 'vue'
import { ToastPlugin, ModalPlugin, CalendarPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

import axiosIns from '@/libs/axios';

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment'



// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2);

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@core/assets/fonts/feather/iconfont.css')


// Create VueI18n instance with options
i18n.locale = store.state.app.language;

Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})
Vue.filter('formatDateMinutes', function(value) {
  if (value) {

    return moment(String(value)).format('DD.MM.YYYY   HH:mm')
  }
})

Vue.filter('formatAmount', function(value) {
  if(value){
   return  'CHF ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&\'');
  }
})
Vue.filter('formatAmountWithoutCurrency', function(value) {
  if(value){
   return  Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&\'');
  }
})
// Retry the request if it initially fails with a 403 status (Forbidden, possibly due to CORS issues)
axiosIns.interceptors.response.use(
  response => response,
  error => {
    // Handle CORS-related errors here
    if (error.response && error.response.status === 403) {
      // Optionally, you can try to re-send the request with credentials
      return axiosIns.request(error.config);
    }

    return Promise.reject(error);
  }
);

import {AuequitexMixins} from './mixins';

// Globally register the mixin
Vue.mixin(AuequitexMixins);

new Vue({
  router,
  store,
  axiosIns,
  i18n,
  render: h => h(App),
}).$mount('#app')
